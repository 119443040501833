<template>
    <div>
        <ts-page-title
            :title="$t('role.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('role.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="$router.push({ name: 'role-create' })"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('role.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
    name: "roleIndex",
    data() {
        return {
            loading: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("auth/role", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.auth.role.search;
            },
            set(newValue) {
                this.$store.commit("auth/role/SET_SEARCH", newValue);
                this.$store.commit("auth/role/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    title: this.$t("role.roleName"),
                    key: "name",
                    sortable: true
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("auth/role/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$toasted.error(error.message);
                });
        },
        onEdit(record) {
            this.$store.commit("auth/role/SET_EDIT_DATA", record);
            this.$router.push({ name: "role-edit", params: { id: record.id } });
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch("auth/role/destroy", record.id)
                .then(response => {
                    this.fetchData();
                    this.$toasted.success(response.message);
                    record._deleting = false;
                })
                .catch(error => {
                    this.$toasted.error(error.message);
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("auth/role/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
